import React, { useState, useContext } from 'react';
import { graphql } from 'gatsby';
import loadable from '@loadable/component';
import PortableText from 'react-portable-text';

import { Container, Typography, Grid, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { EventsHero } from '../components/General/Events/EventsHero';
import { WaveUpSVG } from '../components/WaveSVGs/WaveUpSVG';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Tockify } from '../components/General/Events/Tockify';
import { SEO } from '../components/SEO';
// import { Form } from '../components/ContactForm/Form';
import FormModalContext from '../context/FormModalContext';
import { EventsBody } from '../components/General/Events/EventsBody';

const Form = loadable(() => import('../components/ContactForm/Form'));

const useStyles = makeStyles((theme) => ({
  text: {
    color: '#5A7184',
    '& a': {
      color: theme.workwaveBlue,
    },
  },
  logoSection: {
    marginTop: 0,
    background: 'linear-gradient(180deg, #055291 45.8%, #002D5C 100%)',
    padding: '8rem 0 2rem 0',
    [theme.breakpoints.down('lg')]: {
      marginTop: '-2rem',
    },
  },
  logos: {
    marginTop: '-1rem',
  },
  wave: {
    marginTop: '-10rem',
    [theme.breakpoints.down('lg')]: {
      marginTop: '-9rem',
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: '-8rem',
    },
  },
  bottomHeader: {
    color: theme.workwaveBlue,
    fontWeight: 700,
    paddinng: '1rem 0',
    textAlign: 'center',
  },
}));

const EventsPage = ({ data, location }) => {
  const events = data.events.edges[0].node;
  const classes = useStyles();
  const [selectedTab, setSelectedTab] = useState(0);
  const {
    metaTitle,
    metaDescription,
    hero,
    showProductLogos,
    productLogos,
    eventsHeader,
    tabs,
    eventsCards,
    eventLabels,
    _rawHeader,
  } = events;

  const handleClick = async (e, index) => {
    setSelectedTab(index);
  };

  const { formModalOpen } = useContext(FormModalContext);
  return (
    <>
      <SEO title={metaTitle} description={metaDescription} />
      <EventsHero hero={hero} />

      {showProductLogos ? (
        <div className={classes.logoSection}>
          <Container>
            <Grid
              container
              direction='row'
              alignItems='center'
              spacing={3}
              className={classes.logos}
            >
              {productLogos.map((logo, index) => (
                <Grid
                  container
                  direction='column'
                  alignItems='center'
                  justifyContent='center'
                  item
                  key={index}
                  xs={12}
                  sm={6}
                  md={4}
                >
                  <GatsbyImage
                    image={logo?.image?.asset?.gatsbyImageData}
                    style={
                      {
                        /*width: xs ? '150px' : '275px'*/
                      }
                    }
                  />
                </Grid>
              ))}
            </Grid>
          </Container>
        </div>
      ) : (
        <div>
          <WaveUpSVG height='213' width='100%' fill='white' />
        </div>
      )}
      <Container
        style={{
          marginBottom: '4rem',
          marginTop: showProductLogos ? '2rem' : '-2rem',
        }}
      >
        <Grid
          container
          spacing={4}
          direction='row'
          justifyContent='center'
          alignItems='center'
          style={{ zIndex: 1, position: 'relative' }}
        >
          <Grid item xs={12}>
            <PortableText
              content={_rawHeader}
              serializers={{
                h2: ({ children }) => (
                  <Typography variant='h2' className={classes.bottomHeader}>
                    {children}
                  </Typography>
                ),
              }}
            />
          </Grid>
        </Grid>
        <EventsBody events={eventsCards} eventLabels={eventLabels} />

        {/* <Tockify selectedTab={selectedTab} /> */}
        <Form
          privacy
          modal={false}
          noForm={true}
          location={location}
          pardotUrl='https://go.workwave.com/l/1042281/2023-11-16/65swnn'
        />
      </Container>
      <div style={{ marginTop: '12rem' }}>
        <WaveUpSVG height='213' width='100%' fill='#f4f8ff' />
      </div>
    </>
  );
};

export const query = graphql`
  query EventsPageQuery {
    events: allSanityEvents {
      edges {
        node {
          metaTitle
          metaDescription
          hero {
            image {
              asset {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            ctaText
            ctaLink
            pillUCDate
            pillUCLocation
            _rawHeroText
            backgroundImage {
              asset {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
          showProductLogos
          productLogos {
            image {
              asset {
                gatsbyImageData(placeholder: BLURRED, height: 72)
              }
            }
          }
          _rawHeader
          eventLabels
          eventsCards: events {
            title
            header
            content
            description
            ctaText
            ctaLink
            image {
              asset {
                gatsbyImageData
              }
            }
            tradeshow
            webinar
          }
        }
      }
    }
  }
`;

export default EventsPage;
